import React from 'react'
import '../layout.css';
import { Link } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa';
import { MdLocationOn, MdCall, MdEmail } from "react-icons/md";

export default function Footer() {
    const whatsappUrl = `https://wa.me/7016407160?text=Welcome%20to%20Nick%20International.%20We're%20thrilled%20to%20have%20you%20here.%20Feel%20free%20to%20chat,%20connect%20with%20our%20executive`;
    return (
        <>
        <div>
            <footer class="main-footer">
                <div class="container">
                    <div class="footer-content">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                                <div class="logo-widget footer-widget">
                                    <figure class="logo-box"><a href="#"><img src="/Images/logo.png" alt="" /></a></figure>
                                    <div class="text">
                                        {/* <p>Consectetur adipisicing elit. maxime dolor voluptatibus natus eligendi</p> */}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12 offset-lg-2 footer-column">
                                <div class="service-widget footer-widget">
                                    <div class="footer-title">Services</div>
                                    <ul class="list">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="about">About</a></li>
                                        <li><a href="product">Product & Service</a></li>
                                        <li><a href="contact">Contact</a></li>
                                        <li><a href="term">Terms & Condition</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12 footer-widget">
                                <div class="contact-widget footer-widget">
                                    <div class="footer-title">Contacts</div>
                                    <div class="text">
                                        <p><MdLocationOn /> 255, second floor, opera business center,  near lajamni chowk,  mota varachha,  surat.</p>
                                        <p><MdCall /> +91 70164 07160</p>
                                        <p><MdEmail /> nickinternational09@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 column">
                            <div class="copyright"><a href="#">Anup</a> &copy; {new Date().getFullYear()} All Right Reserved</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 column">
                            <ul class="footer-nav">
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
           <Link to={whatsappUrl} target="_blank" rel="noopener noreferrer">
                 <FaWhatsapp className="whatsapp-icon" />
          </Link>
    
      </>
    )
}

