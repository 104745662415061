import React from 'react'
import AboutContainer from '../../Containers/AboutContainers'

export default function AboutComponent() {
  return (
    <div>
      <AboutContainer />
    </div>
  )
}
