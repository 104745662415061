import React from 'react'
import ContactContainer from '../../Containers/ContactContainer'

export default function ContactComponent() {
  return (
    <div>
        <ContactContainer />
    </div>
  )
}
