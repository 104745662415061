import React, { useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { MdLocationOn, MdCall, MdEmail } from "react-icons/md";
import './contact.css'

export default function ContactContainer() {
    const form = useRef();
    const handleSendMail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_2f6mips', 'template_p1censd', form.current, 'fCH1b6aU_r8Cc9ppl')
            .then((result) => {
                toast.success("Mail Sended Sucessfully!")
                form.current.reset();
            }, (error) => {
                toast.error("Error In sending Mail!")
            });
    };

    return (
        <div>
            <div className="main-banner_contact">
                <div className="content" data-aos="fade-up" data-aos-duration="2500">
                    <h1>Contact us</h1>
                </div>
            </div>

            <div className="main-contact">
                <div className="inner-contact">
                    <div className="right-form" data-aos="fade-up" data-aos-duration="3000">
                        <div className="txt">
                            <div className="inner-padding">
                                <div className="top-txt">
                                    <small>GET IN TOUCH</small>
                                    <h2>
                                        Write <span> Us </span>
                                    </h2>
                                    <p>
                                        Looking for waste paper supplier with huge quantity? nick international Pvt. Ltd. can provide you several types of waste
                                        paper sufficing any volume requirement. Please write us your
                                        queries or requirements and we will get back to you with further
                                        details.
                                    </p>
                                </div>
                                <form ref={form} className="form" onSubmit={handleSendMail}>
                                    <div className="form-col-12">
                                        <div className="form-col-6">
                                            <input type="text" placeholder="First Name" name='from_name' />
                                        </div>
                                        <div className="form-col-6">
                                            <input type="text" placeholder="Your Phone" />
                                        </div>
                                    </div>
                                    <div className="form-col-12">
                                        <input type="text" placeholder="Your Email" name='to_email' />
                                    </div>
                                    <div className="form-col-12">
                                        <input type="text" placeholder="Subject" />
                                    </div>
                                    <div className="form-col-12">
                                        <textarea
                                            name="message"
                                            id=""
                                            cols={40}
                                            rows={10}
                                            placeholder="Message"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div>
                                        <input class="primary_btn" type="submit" value="Submit" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="left-contact" data-aos="fade-up" data-aos-duration="3000">
                        <div className="inner-left-contact">
                            <div className="top-txt">
                                <small>CONNECT WITH US</small>
                                <h2>
                                    Contact <span> Details </span>
                                </h2>
                            </div>
                            <div className="left-text">
                                <div>
                                    <p><MdLocationOn /> 255, second floor, opera business center,  near lajamni chowk,  mota varachha,  surat.</p>
                                </div>
                                <ul>
                                    <li>
                                        {" "}
                                        <MdCall /> +91 70164 07160
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        <MdEmail /> nickinternational09@gmail.com
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
