export const poly_craft_bag = [
    '/Images/White_Waste_Paper/Poly_craft_bag/IMG-20230619-WA0037.jpg',
    '/Images/White_Waste_Paper/Poly_craft_bag/IMG-20230619-WA0038.jpg'
]

export const sop = [
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0045.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0046.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0047.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0048.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0049.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0050.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0051.jpg',
    '/Images/White_Waste_Paper/SOP/IMG-20230619-WA0052.jpg',
]

export const swl = [
    '/Images/White_Waste_Paper/SWL/IMG-20230619-WA0044.jpg',
    '/Images/White_Waste_Paper/SWL/IMG-20230619-WA0053.jpg',
    '/Images/White_Waste_Paper/SWL/IMG-20230619-WA0054.jpg',
]

export const bbc = [
    '/Images/White_Waste_Paper/BBC/BBC KWT Jan 03.jpg',
    '/Images/White_Waste_Paper/BBC/BBC KWT Jan 04.jpg',
    '/Images/White_Waste_Paper/BBC/BBC KWT Jan 02.jpg'
]

export const lp_scan_board = [
    '/Images/White_Waste_Paper/LP_scan_board/0c5985_9a375959ee244f739073bf93c2a91e44~mv2_d_1936_1288_s_2.jpg',
    '/Images/White_Waste_Paper/LP_scan_board/0c5985_2192dc8560b34197b2bc2b43517fd2a8~mv2_d_1936_1288_s_2.jpg',
    '/Images/White_Waste_Paper/LP_scan_board/1 (1) (16).jpg',
    '/Images/White_Waste_Paper/LP_scan_board/1 (2) (16).jpg',
    '/Images/White_Waste_Paper/LP_scan_board/1 (3) (15).jpg',
    '/Images/White_Waste_Paper/LP_scan_board/1 (5) (9).jpg',
    '/Images/White_Waste_Paper/LP_scan_board/1 (6) (11).jpg',
]

export const onp = [
    '/Images/White_Waste_Paper/ONP/imagenocrop-0_800_nocrop_1602290254.jpg',
    '/Images/White_Waste_Paper/ONP/proton-brown-recycle-kraft-paper-packaging-type-roll-2_b9612718bcc2497a94e54f9daf10738b_master.jpg'
]

export const poc = [
    '/Images/White_Waste_Paper/POC/POC KWT JAK01.jpg',
]

export const polycup = [
    '/Images/White_Waste_Paper/Polycup/1 (1) (11).jpg',
    '/Images/White_Waste_Paper/Polycup/1 (2) (10).jpg',
    '/Images/White_Waste_Paper/Polycup/1 (3) (10).jpg'
]

export const tissue_in_bales = [
    '/Images/White_Waste_Paper/Tissue_in_bales/1 (2) (16).jpg',
    '/Images/White_Waste_Paper/Tissue_in_bales/1 (5) (9).jpg',
    '/Images/White_Waste_Paper/Tissue_in_bales/1 (6) (11).jpg'
]

export const DLK = [
    '/Images/Brown_waste_paper/DLK/208--1536x1395.jpg',
    '/Images/Brown_waste_paper/DLK/IMG_4239.jpg'
]

export const DS_OCC = [
    '/Images/Brown_waste_paper/DS_OCC/IMG-20230521-WA0025.jpg',
    '/Images/Brown_waste_paper/DS_OCC/IMG-20230521-WA0026.jpg'
]

export const DSOCC_Premium = [
    '/Images/Brown_waste_paper/DSOCC _Premium/DSOCC1.jpeg',
    '/Images/Brown_waste_paper/DSOCC _Premium/DS-primum-1.jpg',
    '/Images/Brown_waste_paper/DSOCC _Premium/DSOCC.jpeg'
]

export const fruit_box = [
    '/Images/Brown_waste_paper/Fruit_box/IMG-20230913-WA0023.jpg',
    '/Images/Brown_waste_paper/Fruit_box/IMG-20230913-WA0024.jpg',
    '/Images/Brown_waste_paper/Fruit_box/IMG-20230913-WA0026.jpg',
    '/Images/Brown_waste_paper/Fruit_box/IMG-20230913-WA0028.jpg'
]

export const gay_lord_box = [
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0004.jpg',
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0005.jpg',
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0006.jpg',
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0007.jpg',
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0008.jpg',
    '/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0009.jpg',
]

export const hard_mix = [
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0036.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0037.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0038.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0039.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0040.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0041.jpg',
    '/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0042.jpg',
]

export const kcb = [
    '/Images/Brown_waste_paper/KCB/1 (1) (15).jpg',
    '/Images/Brown_waste_paper/KCB/1 (2) (15).jpg',
    '/Images/Brown_waste_paper/KCB/1 (3) (13).jpg',
    '/Images/Brown_waste_paper/KCB/1 (4) (11).jpg',
    '/Images/Brown_waste_paper/KCB/1 (4) (12).jpg',
    '/Images/Brown_waste_paper/KCB/1 (5) (8).jpg',
    '/Images/Brown_waste_paper/KCB/1 (6) (10).jpg',
    '/Images/Brown_waste_paper/KCB/1 (7) (5).jpg',
    '/Images/Brown_waste_paper/KCB/KCB in Bales (3).jpg'
]

export const ncc = [
    '/Images/Brown_waste_paper/NCC/NCC (2).jpg',
    '/Images/Brown_waste_paper/NCC/NCC (3).jpg',
    '/Images/Brown_waste_paper/NCC/NCC (4).jpg',
    '/Images/Brown_waste_paper/NCC/NCC (5).jpg',
    '/Images/Brown_waste_paper/NCC/NCC (6).jpg'
]

export const occ_10 = [
    '/Images/Brown_waste_paper/Occ_10/IMG-20230818-WA0015.jpg',
    '/Images/Brown_waste_paper/Occ_10/IMG-20230818-WA0018.jpg',
    '/Images/Brown_waste_paper/Occ_10/IMG-20230818-WA0022.jpg',
    '/Images/Brown_waste_paper/Occ_10/IMG-20230818-WA0022.jpg'
]

export const occ_11 = [
    '/Images/Brown_waste_paper/Occ_11/IMG-20230312-WA0010.jpg',
    '/Images/Brown_waste_paper/Occ_11/IMG-20230312-WA0011.jpg',
    '/Images/Brown_waste_paper/Occ_11/IMG-20230312-WA0016.jpg'
]

export const occ_12 = [
    '/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0002.jpg',
    '/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0003.jpg',
    '/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0004.jpg',
    '/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0006.jpg',
    '/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0008.jpg'
]

export const occ_95 = [
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0011.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0014.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0015.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0016.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0017.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0018.jpg',
    '/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0019.jpg'
]

export const occ_90_10 = [
    '/Images/Brown_waste_paper/OCC90-10/IMG-20230816-WA0010.jpg',
    '/Images/Brown_waste_paper/OCC90-10/IMG-20230816-WA0012.jpg',
    '/Images/Brown_waste_paper/OCC90-10/IMG-20230816-WA0013.jpg',
    '/Images/Brown_waste_paper/OCC90-10/IMG-20230816-WA0014.jpg'
]

export const select_occ = [
    '/Images/Brown_waste_paper/Select_occ/IMG-20230818-WA0016.jpg',
    '/Images/Brown_waste_paper/Select_occ/IMG-20230818-WA0021.jpg',
    '/Images/Brown_waste_paper/Select_occ/IMG-20230818-WA0023.jpg'
]

export const soft_mix = [
    '/Images/Brown_waste_paper/Soft_mix/1 (3) (14).jpg',
    '/Images/Brown_waste_paper/Soft_mix/soft-mix-1.jpeg',
    '/Images/Brown_waste_paper/Soft_mix/soft-mix-2.jpeg',
    '/Images/Brown_waste_paper/Soft_mix/soft-mix.jpeg'
]

export const tobbaco_Box = [
    '/Images/Brown_waste_paper/Tobbaco_Box/IMG-20230828-WA0013.jpg',
    '/Images/Brown_waste_paper/Tobbaco_Box/IMG-20230828-WA0015.jpg',
    '/Images/Brown_waste_paper/Tobbaco_Box/IMG-20230828-WA0016.jpg'
]

export const Currugated = [
    '/Images/CurrugatedWastePaper/Currugated-2.jpeg',
    '/Images/CurrugatedWastePaper/Currugated.jpeg',
    '/Images/CurrugatedWastePaper/Currugated_1.jpeg'
]

export const Duplex = [
    '/Images/Duplex_Wast_Paper/Duplex.jpeg',
    '/Images/Duplex_Wast_Paper/Duplex_1.jpeg'
]