import React from 'react'
import './term.css'
import { Link } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa';

export default function TermContainer() {
    const whatsappUrl = `https://wa.me/9925579066?text=Hello%20from%20my%20website`;
    return (
        <div class="page">
            <div id="terms-and-conditions">
                <h1 className='mb-4'>Terms & Conditions Template</h1>
                <div>
                    <p>1. IN EVENT OF POTENTIAL COMPLAINT, CONTAINER DE-STUFFING IS TO BE STOPPED TOWARDS IMMEDIATE INSPECTION CAN BE ARRANGED AT THE TIME OF DE-STUFFING AND SHIPPER WILL HAVE TO PAY DETENTION AND OTHER CHARGES OR INSPECTION SHOULD BE CARRIED OUT WITHIN IN 3 DAYS FROM THE DAY OF DE-STUFFING IN FACTORY.</p>
                    <p>2. CLAIMS SETTLEMENT MUST BE WITHIN ONE MONTH FROM THE REPORTED/INSPECTION DATE.</p>
                    <p>3. 14/12/10 DAYS FREE DETENTION PERIOD AT DESTINATION.</p>
                    <p>4. OUT THROWS AND PROHIBITIVES AS PER THE INDENT, MOISTURE UNDER 12% IS ALLOWABLE.</p>
                    <p>5. EITHER LATE OR WRONG DOCUMENTATION CAUSE DETENTION/G.RENT/CUSTOM PENALTY
                        WHICH IS ALWAYS LIABLE TO BENEFICIARY/SUPPLIER ACCOUNT AND MUST BE PAYABLE ON SIGHT AT THE TIME OF CLEARANCE.</p>
                    <p>6. ORIGINAL DOCS SHOULD REACH AT BANK 10 DAYS PRIOR TO THE DATE ARRIVAL OF THE VESSEL AND IN SET SHOULD BE PROVIDED ON MAIL ATLEAST 2 WEEKS PRIOR TO THE DATE ARRIVAL OF THE VESSEL.</p>
                    <p>7. SHIPMENT SCHEDULE SHOULD BE PROVIDED TO US ON WEEKLY BASIS.</p>
                    <p>8. SHIPPING LINE SHOULD BE MAERSK/MSC DEPENDING ON THE COUNTRY OF SHIPMENT AND WILL BE DISCUSSED AT THE TIME OF SALES CONTRACT.</p>
                    <p>9. CORDELIA/COSCO SHIPPING LINE CAN BE ACCEPTED ONLY BEFORE PRIOR CONFIRMATION OF THE DESTINATION CHARGES AND PRIOR INFORMATION OF THE OTHER CONDITIONS OF THE SHIPPING LINE EX. SECURITY DEPOSIT REQUIRED BY THEM ETC.</p>
                    <p>
                        <span>Note:- </span>
                        PLS ENSURE TO SEND US FULL SET OF ORIGINAL DOCUMENTS (INVOICE, BL, PL,CERTIFICATE OF ORIGIN, PSIC FORM 9/6, CHEMICAL ANALYSIS ETC.) DULY SIGNED & STAMPED AS SAME IS REQUIRED WHILE CUSTOM NOTING OF DOCUMENT AND UPLOADING THROUGH E- SANCHIT MODULE.
                    </p>
                </div>
                {/* <Link to={whatsappUrl} target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp className="whatsapp-icon" />
                </Link> */}
            </div>
        </div>
    )
}
