import React from 'react'
import CountUp from 'react-countup';
import { Col, Container, Row } from 'react-bootstrap'
import { MdAirplanemodeActive, MdNewspaper, MdOutlineLanguage, MdOutlineGroup } from "react-icons/md";
import './experience.css'

export default function Experience() {
    return (
        <Container className='lineUp'>
            <Row md={3} style={{ justifyContent: 'space-evenly' }}>
                <Col xs={6} md={4} className='experience'>
                    <div>
                        <MdAirplanemodeActive color='#4a4aff' className="icon" />
                    </div>
                    <CountUp className='counter' end={20} /><span>+</span>
                    <p>
                        {" "}
                        Years of experience in Waste Paper Supply
                    </p>
                </Col>
                <Col xs={6} md={4} className='experience'>
                    <div>
                        <MdNewspaper color='#4a4aff' className="icon" />
                    </div>
                    <CountUp end={65} className='counter' /><span>+</span>
                    <p>Tons of Waste Paper Imported Yearly</p>
                </Col>
                <Col xs={6} md={4} className='experience'>
                    <div>
                        <MdOutlineLanguage color='#4a4aff' className="icon" />
                    </div>
                    <CountUp end={25} className='counter' /><span>+</span>
                    <p>Countries Paper Imports</p>
                </Col>
                <Col xs={6} md={4} className='experience'>
                    <div>
                        <MdOutlineGroup color='#4a4aff' className="icon" />
                    </div>
                    <CountUp end={20} className='counter'/><span>+</span>
                    <p> Reputed Suppliers</p>
                </Col>
            </Row>
        </Container>
    )
}
