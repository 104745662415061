import React, { useRef } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import './getintouch.css';

export default function GetInTouch() {
    const form = useRef();
    const handleSendMail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_2f6mips', 'template_p1censd', form.current, 'fCH1b6aU_r8Cc9ppl')
            .then((result) => {
                toast.success("Mail Sended Sucessfully!")
                form.current.reset();
            }, (error) => {
                toast.error("Error In sending Mail!")
            });
    };
    return (
        <Container className='container touch-container mb-5'>
            <Row className='touch-row'>
                <Col md={8} className='top-content'>
                    <h1 class="text-primary">
                        <b className='animate-charcter'>GET IN TOUCH</b>
                    </h1>
                    <p class="text-muted text-center small">
                        A LEAD UX & UI DESIGNER BASED IN CANADA
                    </p>
                </Col>
            </Row>
            <div className='touch-row-2 mt-3 mb-5'>
                <h3 style={{ marginBottom: '4%', fontWeight: '900' }}>SAY SOMETHING</h3>
                <Form ref={form}  onSubmit={handleSendMail}>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Name *" name='from_name' />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="email" placeholder="Email *" name='to_email' />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder="Subject *" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" placeholder='Your Message *' name="message" rows={3} />
                    </Form.Group> 
                    <Button type='submit' class="primary_btn" variant="primary">Submit</Button>
                </Form>
                <ToastContainer />
            </div>
        </Container>
    )
}
