import React from 'react'
import TermContainer from '../../Containers/TermContainer'

export default function TermComponent() {
  return (
    <div>
        <TermContainer />
    </div>
  )
}
