import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
// import details from '../../../JsonData/ProductDetails';
const DynamicData = require('../../../JsonData/ProductDetails')

export default function DynamicPage() {
  const { dynamicParam } = useParams();
  const { pathname } = useLocation()
  const Array = pathname.split('/');
  const lastIndex = Array.length - 1;
  const value = Array[lastIndex]
  return (
    <div>
      <div>
        <section className="team">
          <div className="container">
            <div className="top-txt">
              <h2>
                Our Products
              </h2>
            </div>
            <div className="team_main-box flex">
              {
                DynamicData[value]?.map((item) =>
                  <div className="col-lg-4 col-md-6 col-sm-12 img_box">
                    <div className="single_box">
                      <div>
                        <img src={item} alt="" />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
