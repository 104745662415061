import HomeComponent from './Components/HomeComponent';
import {  Routes, Route } from "react-router-dom";

import Footer from './Layout/Footer';
import Header from './Layout/Header';
import AboutComponent from './Components/AboutComponent';
import ProductComponent from './Components/ProduceComponent';
import ContactComponent from './Components/ContactComponent';
import TermComponent from './Components/TermComponent';
import Wastepaper from './Containers/ProductContainer/WastePaper';
import BrownWastePaper from './Containers/ProductContainer/WastePaper/BrownWastePaper';
import WhiteWastePaper from './Containers/ProductContainer/WastePaper/WhiteWastepaper';
import FinishedContainer from './Containers/ProductContainer/FinishedPaper';
import CurrugatedWastePaper from './Containers/ProductContainer/FinishedPaper/CurrugatedWastePaper';
import DuplexWastePaper from './Containers/ProductContainer/FinishedPaper/DuplexWastePaper';
import DynamicPage from './Containers/ProductContainer/DynamicPage';

function App() {
  return (
    <div>
      <Header />
        <Routes>
            <Route index element={<HomeComponent />} />
            <Route path="about" element={<AboutComponent />} />
            <Route path="product" element={<ProductComponent />} />
            <Route path="contact" element={<ContactComponent />} />
            <Route path="term" element={<TermComponent />} />
            <Route path="product/category/finishedpaper" element={<FinishedContainer />} />
            <Route path="product/category/wastepaper" element={<Wastepaper />} />
            <Route path="product/category/wastepaper/brownwastepaper" element={<BrownWastePaper />} />
            <Route path="product/category/wastepaper/brownwastepaper/:dynamicParam" element={<DynamicPage />} />
            <Route path="product/category/wastepaper/whitewastepaper/:dynamicParam" element={<DynamicPage />} />
            <Route path="product/category/wastepaper/whitewastepaper" element={<WhiteWastePaper />} />
            <Route path="product/category/finishedpaper/corrugated" element={<CurrugatedWastePaper />} />
            <Route path="product/category/finishedpaper/duplex" element={<DuplexWastePaper />} />
          
        </Routes>
      <Footer />
      
    </div>
  );
}

export default App;

