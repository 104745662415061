
export const PortfolioData = [
    {
        name: "Waste paper",
        image: "/Images/wastepaper.jpeg",
        to:'category/wastepaper'
    },
    {
        name: "Finished Paper",
        image: "/Images/finished.jpeg",
        to:'category/finishedpaper'
    },
]

export const WastepaperData = [
    {
        name: "Brown Waste Paper",
        image: "/Images/brownWastepaper.jpg",
        to:'brownwastepaper'
    },
    {
        name: "White Waste Paper",
        image: "/Images/whiteWastepaper.jpg",
        to:'whitewastepaper'
    },
]

export const FinishedpaperData = [
    {
        name: "Corrugated Waster Paper",
        image: "/Images/CurrugatedWastePaper/Currugated.jpeg",
        to:'corrugated'
    },
    {
        name: "Duplex Waster Paper",
        image: "/Images/Duplex_Wast_Paper/Duplex.jpeg",
        to:'duplex'
    },
]

export const CategoryOfWhiteWastePaper = [
    {
        name: "Poly Craft Bag",
        image: "/Images/White_Waste_Paper/Poly_craft_bag/IMG-20230619-WA0037.jpg",
        to:'poly_craft_bag'
    },
    {
        name: "SOP",
        image: "/Images/White_Waste_Paper/SOP/IMG-20230619-WA0045.jpg",
        to:'sop'
    },
    {
        name: "SWL",
        image: "/Images/White_Waste_Paper/SWL/IMG-20230619-WA0044.jpg",
        to:'swl'
    },
    {
        name: "BBC",
        image: "/Images/White_Waste_Paper/BBC/BBC KWT Jan 04.jpg",
        to:'bbc'
    },
    {
        name: "LP Scan Board",
        image: "/Images/White_Waste_Paper/LP_scan_board/0c5985_9a375959ee244f739073bf93c2a91e44~mv2_d_1936_1288_s_2.jpg",
        to:'lp_scan_board'
    },
    {
        name: "ONP",
        image: "/Images/White_Waste_Paper/ONP/imagenocrop-0_800_nocrop_1602290254.jpg",
        to:'onp'
    },
    {
        name: "POC",
        image: "/Images/White_Waste_Paper/POC/POC KWT JAK01.jpg",
        to:'poc'
    },
    {
        name: "Polycup",
        image: "/Images/White_Waste_Paper/Polycup/1 (1) (11).jpg",
        to:'polycup'
    },
    {
        name: "Tissue In Bales",
        image: "/Images/White_Waste_Paper/Tissue_in_bales/1 (2) (16).jpg",
        to:'tissue_in_bales'
    }
]

export const CategoryOfBrownWastePaper = [
    {
        name: "DLK",
        image: "/Images/Brown_waste_paper/DLK/208--1536x1395.jpg",
        to:'DLK'
    },
    {
        name: "DS OCC",
        image: "/Images/Brown_waste_paper/DS_OCC/IMG-20230521-WA0025.jpg",
        to:'DS_OCC'
    },
    {
        name: "DSOCC Premium",
        image: "/Images/Brown_waste_paper/DSOCC _Premium/DSOCC.jpeg",
        to:'DSOCC_Premium'
    },
    {
        name: "Fruit Box",
        image: "/Images/Brown_waste_paper/Fruit_box/IMG-20230913-WA0023.jpg",
        to:'fruit_box'
    },
    {
        name: "Gay Lord Box",
        image: "/Images/Brown_waste_paper/Gay_lord_Box/IMG-20230524-WA0004.jpg",
        to:'gay_lord_box'
    },
    {
        name: "Hard Mix",
        image: "/Images/Brown_waste_paper/Hard_mix/IMG-20230523-WA0036.jpg",
        to:'hard_mix'
    },
    {
        name: "KCB",
        image: "/Images/Brown_waste_paper/KCB/KCB in Bales (3).jpg",
        to:'kcb'
    },
    {
        name: "NCC",
        image: "/Images/Brown_waste_paper/NCC/NCC (6).jpg",
        to:'ncc'
    },
    {
        name: "Occ 10",
        image: "/Images/Brown_waste_paper/Occ_10/IMG-20230818-WA0015.jpg",
        to:'occ_10'
    },
    {
        name: "Occ 11",
        image: "/Images/Brown_waste_paper/Occ_11/IMG-20230312-WA0010.jpg",
        to:'occ_11'
    },
    {
        name: "Occ 12",
        image: "/Images/Brown_waste_paper/Occ_12/IMG-20230509-WA0006.jpg",
        to:'occ_12'
    },
    {
        name: "OCC 95/5",
        image: "/Images/Brown_waste_paper/Occ_95_5/IMG-20230616-WA0011.jpg",
        to:'occ_95'
    },
    {
        name: "OCC 90/10",
        image: "/Images/Brown_waste_paper/OCC90-10/IMG-20230816-WA0010.jpg",
        to:'occ_90_10'
    },
    {
        name: "Select Occ",
        image: "/Images/Brown_waste_paper/Select_occ/IMG-20230818-WA0021.jpg",
        to:'select_occ'
    },
    {
        name: "Soft Mix",
        image: "/Images/Brown_waste_paper/Soft_mix/1 (3) (14).jpg",
        to:'soft_mix'
    },
    {
        name: "Tobbaco Box",
        image: "/Images/Brown_waste_paper/Tobbaco_Box/IMG-20230828-WA0015.jpg",
        to:'tobbaco_Box'
    },
] 