import React from 'react';
import './about.css';
import { Link } from 'react-router-dom';

export default function About() {
    return (
        <div>
            <div className="main-welcome">
                <div className="container">
                    <div className="row main-content">
                    <div className="col-lg-6 col-md-12 col-sm-12 right-img lineleft">
                            <div>
                                <img src="/Images/about.jpeg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 left-content lineright">
                            {/* <div className="txt-p">
                                <p>
                                    We take immense pride in our pivotal role within the industry, serving as a steadfast catalyst for Earth's conservation.
                                    As a leading waste paper importer in India, our steadfast commitment to sustainability has enabled us to make substantial
                                    contributions to the recycling efforts of millions of tons of waste paper sourced from prominent regions such as the USA,
                                    Canada, Europe, Middle East, and Africa. By efficiently channeling these materials into the recycling process, we actively
                                    contribute to the preservation of our planet's invaluable resources, fostering a more sustainable and eco-conscious future
                                    for generations to come.
                                </p>
                            </div> */}
                            <div class="">
                                <div class="main_title lets-content text-left">
                                    <h2>let’s <br />
                                        Introduce about <br />
                                        Our Business</h2>
                                    <p class="about-content">
                                        At the heart of our operations lies a profound sense of pride in the pivotal role we play within our industry,
                                        standing as an unwavering catalyst for the cause of Earth's conservation. As a foremost waste paper importer in India,
                                        our unwavering dedication to sustainability serves as the cornerstone of our identity.
                                    </p>
                                    <p class="about-content">
                                        Our journey toward environmental preservation takes shape through our resolute actions.
                                        By expertly channeling these diverse waste paper materials into the recycling cycle, we bridge
                                        the gap between waste and renewal.
                                    </p>
                                    <Link class="primary_btn" to={'/about'}><span >About Us</span></Link>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
