import React from 'react'
import Welcome from '../../Containers/HomeContainers/Content'
import About from '../../Containers/HomeContainers/about'
import GetInTouch from '../../Containers/HomeContainers/GetInTouch'
import Wastepaper from '../../Containers/HomeContainers/Wastepaper'

export default function HomeComponent() {
  return (
    <div>
        <Welcome />
        <About />
        <GetInTouch />
        <Wastepaper />
    </div>
  )
}
