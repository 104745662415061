import React from 'react'
import { Link } from 'react-router-dom';
import '../product.css';
import { WastepaperData } from '../../../JsonData/portfolioJson';

export default function WastePaperContainer() {
    return (
        <div>
            <div className="main-banner">
                <div className="container">
                    <div className="content" data-aos="fade-up" data-aos-duration="2500">
                        <h1>Products</h1>
                    </div>
                </div>
            </div>
            <section className="team">
                <div className="container">
                    <div className="top-txt">
                        {/* <small>12+ TYPES OF OCC GRADE PAPER</small> */}
                        <h2>
                            Our Products
                        </h2>
                    </div>
                    <div className="team_main-box flex">
                        {
                            WastepaperData?.map((item) =>
                                <Link to={item.to} className="col-lg-4 col-md-6 col-sm-12 img_box">
                                    <div className="single_box">
                                        <div>
                                            <img src={item.image} alt="" />
                                            <div className="team_text">
                                                <h3>{item.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                    </div>
                </div>
            </section>
        </div>
    )
}
