import React from 'react'
import { Currugated } from '../../../../JsonData/ProductDetails'

export default function CurrugatedWastePaper() {
  return (
    <div>
    <div>
      <section className="team">
        <div className="container">
          <div className="top-txt">
            <h2>
              Our Products
            </h2>
          </div>
          <div className="team_main-box flex">
            {
              Currugated?.map((item) =>
                <div className="col-lg-4 col-md-6 col-sm-12 img_box">
                  <div className="single_box">
                    <div>
                      <img src={item} alt="" />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}