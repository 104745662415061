import React from 'react'
import './welcome.css';


export default function Welcome() {
    return (
        <div class="home_banner_area home-back space pt-4">
            <div class="banner_inner">
                <div className='container'>
                <div class="row">
                    <div class="col-lg-7 col-md-12 col-sm-12">
                        <div class="banner_content lineUp">
                            <h6>Welcome.....</h6>
                            <h1 class="text-uppercase">Empowering Waste Paper Recycling</h1>
                            <h5 class="text-uppercase">Through our dedication to empowering waste paper recycling, we are reshaping the narrative of sustainability,
                                breathing new life into discarded fibers, and crafting a brighter future one recycled sheet at a time.</h5>
                            <div class="d-flex align-items-center btn">
                                <a class="primary_btn" href="#"><span>Learn More</span></a>
                            </div>
                        </div>
                    </div> 
                    <div class="col-lg-5 col-md-12 col-sm-12">
                        <div class="home_right_img">
                            {/* <img src="/Images/recycle.jpg" alt="" /> */}
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
