import React from 'react'
import ProductContainer from '../../Containers/ProductContainer'

export default function ProductComponent() {
  return (
    <div>
        <ProductContainer />
    </div>
  )
}
