import React, { useState } from 'react'
import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import '../layout.css';
import { Link, useLocation } from 'react-router-dom';
// import React, { useEffect, useState } from "react";



export default function Header() {
    const { pathname } = useLocation()
    const [open, setOpen] = useState(false)
    const Header = [
        { name: 'Home', to: '/' },
        { name: 'About', to: '/about' },
        { name: 'Product & Service', to: '/product' },
        { name: 'Contact', to: '/contact' },
        { name: 'Term & Condition', to: '/term' },
    ]

    return (
        <div>
            <div class="header-area">
                <div class="container sticky-area">
                    <div class='header-content'>
                        <div class="header-inner-box">
                            <Link to='/' class="logo">
                                <img src='/Images/logo.png' height={80} width={120} />
                            </Link>
                            <div class="main-menu">
                                <nav class="navbar navbar-expand-lg">
                                    <div class={`${open ? 'navbar-nav' : 'collapse d-none d-lg-block navbar-collapse justify-content-center'}`} id="navbarSupportedContent">
                                        <ul class="navbar-nav m-auto">
                                            {
                                                Header?.map((item) =>
                                                    <li class="nav-item">
                                                        <Link class={`nav-link ${pathname === item.to && 'headerBorder'}`} to={item.to}>{item.name}</Link>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <div className='d-block d-lg-none toggle'>
                                        {
                                            open ? (
                                                <MdClose onClick={() => setOpen(!open)} />  
                                            ) : (
                                                <FaBars onClick={() => setOpen(!open)} />
                                            )
                                        }
                                    </div>
                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}