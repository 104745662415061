import React from 'react'
import './aboutpage2.css'
import Experience from '../HomeContainers/experience'

export default function HomeContainer() {
    return (
        <div>
            <div className="main-banner about-banner">
                <div>
                    <div className="content" data-aos="fade-up" data-aos-duration="2500">
                        <h1 className='lineUp'>About Us</h1>
                    </div>
                </div>
            </div>
            <div className="main-welcome aboutpage2">
                <div className="container">
                    <div className="main-content">
                        <div className="col-lg-6 col-md-12 col-sm-12 left-content lineleft">
                            <div className='content'>
                            <div className="top-txt">
                                <small>Welcome to</small>
                                <h2>
                                    About Us
                                </h2>
                            </div>
                            <div className="txt-p">
                                <p>
                                    We take immense pride in our pivotal role within the industry, serving as a steadfast catalyst for Earth's conservation.
                                    As a leading waste paper importer in India, our steadfast commitment to sustainability has enabled us to make substantial
                                    contributions to the recycling efforts of millions of tons of waste paper sourced from prominent regions such as the USA,
                                    Canada, Europe, Middle East, and Africa. By efficiently channeling these materials into the recycling process, we actively
                                    contribute to the preservation of our planet's invaluable resources, fostering a more sustainable and eco-conscious future
                                    for generations to come.
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 right-img lineright">
                            <div>
                                <img src="/Images/work.jpg" height={400} width={500} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Experience />
        </div>
    )
}
