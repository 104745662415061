import React from 'react'
import './wastepaper.css';
import { Link } from 'react-router-dom';

export default function Welcome() {
    return (
        <div class="home_banner_area main-watse">
            <div class="banner_inner inner-waste">  
                <div className='container'>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="banner_content">
                            <h1 class="text-uppercase">ALL GRADES OF</h1>
                            <h5 class="text-uppercase">Waste Paper</h5>
                           
                        </div>
                    </div> 
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="home_right_img">
                            <div>
                                <p>
                                All the raw material is imported only from trustworthy and proven suppliers. We try to identify the best sources of waste paper from all across the world and only those with a high commitment to quality become a part of the supplier list.                                </p>
                            </div>
                        <div class="d-flex align-items-center btn">
                                <Link class="primary_btn" to={'/product'}><span>Product</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
